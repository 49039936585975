<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">فوترة</h2>
        </b-link>

        <b-card-title class="mb-1"> تبدأ المغامرة هنا 🚀 </b-card-title>
        <b-card-text class="mb-2">
          التكلفة لا تقارن بما تقدمه منصة فوترة من مزايا، ابدأ بالتجربة
          ولن تندم!
        </b-card-text>
        <b-alert v-model="showMessage" dismissible :variant="messageType">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="InfoIcon" />
            <span class="ml-25" v-html="message"></span>
          </div>
        </b-alert>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="email" label="البريد الالكتروني">
              <validation-provider
                vid="email"
                name="email"
                v-slot="{ errors }"
                rules="required|validEmail"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">كلمة المرور</label>
                <b-link :to="{ name: 'auth-forgot-password-v2' }">
                  <small>هل نسيت كلمة السر؟</small>
                </b-link>
              </div>
              <validation-provider
                vid="password"
                name="password"
                v-slot="{ errors }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="remember_me"
                value="true"
                name="remember_me"
              >
                تذكرنى
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              @click="validationForm"
              block
            >
              تسجيل الدخول
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>جديد على منصة فوترة لدينا؟ </span>
          <b-link :to="{ name: 'register' }">
            <span>&nbsp;إنشاء حساب</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="twitter">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="google">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="github">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BButton,
  BAlert,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { required, validEmail } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useJwt from "@/auth/jwt/useJwt";
import {  setUserData } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  mixins: [togglePasswordVisibility, alertMessageMixin],
  data() {
    return {
      email: '',
      password: '',
      remember_me: false,
      // validation rules
      required,
      validEmail,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    async validationForm() {
      this.showAlertMessage = false;
      this.errorMessage = '';

      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          try {
            const response = await this.$http.post('/login', {
              email: this.email,
              password: this.password,
              remember_me: this.remember_me,
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم التسجيل بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            console.log(response.data);
            this.displayMessage({
              message: response.data.message,
              type: 'success',
            });
            useJwt.setToken(response.data.accessToken);
            setUserData(response.data.user);
            // useJwt.setRefreshToken(response.data.refreshToken)
            // this.$ability.update(response.data.userData.ability)
            this.$router.push('/');
          } catch (error) {
            let errorMessage = {
              message: '',
              type: 'danger',
            };
            
            const { errors } = error.response.data;
            if (errors) {
              Object.values(errors).forEach(error => {
                errorMessage.message += `${error[0]}<br>`;
              });
              this.$refs.loginForm.setErrors(error.response.data.errors ?? []);
            } else {
              errorMessage.message = error.response.data.message;
            }
            
            this.displayMessage(errorMessage);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                text: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          }
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>